<template>
  <div class="relative">
    <h3 class="text-space-green geminis text-2xl">
      {{ team.name }}
    </h3>
    <div class="bg-white text-black w-3/4 rounded-xl flex flex-col items-center">
      <div>
        <div
          v-if="team.users.length > 0"
          class="pt-14 h-32 font-bold text-xl text-center text-space-dark-blue"
        >
          <ul class="px-0">
            <li
              v-for="user in team.users"
              :key="user.id"
            >
              {{ user.pseudo }}
            </li>
          </ul>
        </div>
        <div
          v-else
          class="pt-14 h-32 font-bold text-xl text-center"
        >
          <p>{{ $t('games.team.empty_team') }}</p>
        </div>
      </div>

      <button
        class="bg-space-dark-blue mb-10 text-white hover:bg-white hover:text-space-dark-blue text-center rounded-full font-bold py-2 px-1 text-xs xl:text-base cursor-pointer shadow-space w-3/5"
        @click="joinTeamEmit"
      >
        {{ $t('games.team.join_team') }}
      </button>
    </div>
    <div class="team-img absolute bg-space-dark-blue rounded-full h-24 w-24 -top-5">
      <img
        :data-src="require('@/assets/images/satelite.png')"
        alt="team_avatar"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamList",
  props: ['team'],
  methods: {
    joinTeamEmit() {
      this.$emit('join-team', {teamId: this.team.id});
    }
  }
};
</script>

<style scoped>
  .team-img {
    right: 19%;
  }
</style>
