<template>
  <div id="roomTeam" v-lazy-container="{selector: 'img'}">
    <SimpleTopBar />
    <!--  ml-4 AS the square have mx-4  -->
    <BackToSpaceButton class="ml-4" />
    <div class="h-full bg-space-dark-blue border-green shadow-space rounded-lg mx-4 mt-1 p-1">
      <div class="h-full flex flex-col content-around justify-around">
        <h3 class="geminis text-space-green mb-4 mt-4 flex justify-center text-xl md:text-2xl lg:text-4xl">
          {{ $t('games.team.title') }}
        </h3>

        <!-- Teams -->
        <div
          v-if="room"
          class="flex justify-between ml-44 flex-col lg:flex-row"
        >
          <!-- Team 1 -->
          <div
            v-for="team in teams"
            :key="team.id"
            class="lg:w-1/2"
          >
            <TeamList
              :team="team"
              @join-team="joinTeam"
            />
          </div>
        </div>

        <div class="w-full flex flex-col items-center lg:mb-10 mt-12">
          <PrimaryButton
            v-if="owner && owner.id === user.id"
            :bg-color="'bg-white'"
            :text="$t('games.team.launch_game').toString()"
            :classes="'text-space-dark-blue h-12 hover:text-white'"
            @click="validateTeams"
          />

          <div v-else>
            {{ $i18n.t('games.team.waiting_for_owner') }}
          </div>

          <div
            v-if="err"
            class="text-red-500 bg-white text-center w-2/5 rounded-lg mt-2"
          >
            {{ err }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RoomConfigurationService from "../../services/roomConfigurationService";
import GameService from "../../services/gameService";
import TeamList from "../../components/Room/Team/TeamList.vue";
import {ROUTE_GAME} from "../../router";
import SimpleTopBar from "@/layouts/partials/other-layout/SimpleTopBar.vue";
import BackToSpaceButton from "../../components/buttons/BackToSpaceButton.vue";
import PrimaryButton from "../../components/buttons/PrimaryButton.vue";
import {UPDATE_ROOM_STATE} from "../../store/modules/userModule/actions";
import {GAME_IN_PROGRESS} from "../../constants/games/GameStatusConstants";

export default {
  name: "RoomTeam",
  components: {PrimaryButton, BackToSpaceButton, TeamList, SimpleTopBar},
  data() {
    return {
      user: null,
      room: null,
      game: null,
      err: null,
      owner: null,
      teams: []
    };
  },
  async mounted() {
    // Get user
    this.user = this.$store.getters.getUser;
    // Get room details
    this.room = await RoomConfigurationService.getRoom(this.$route.params.pin);
    // Get team details
    this.teams = await GameService.getTeamsFromGameId(this.room.game.id);
    // Get owner
    this.owner = this.room.owner;
  },
  methods: {
    async joinTeam(payload) {
      const teamId = payload.teamId;
      if (!this.user || this.checkNotAlreadyInTeam(teamId)) {
        return;
      }
      // Update user team
      await GameService.updateUserTeam(this.user.id, teamId);

      // Emit event
      this.$socket.client.emit('userJoinsTeam', {
        pin: this.$route.params.pin
      });
    },

    checkNotAlreadyInTeam(teamId) {
      const team = this.teams.find((team) => team.id === teamId);
      return !!team.users.find((user) => user.id === this.user.id);
    },

    validateTeams() {
      this.err = '';
      let team = this.teams.find((team) => team.users.length === 0);
      if (team) {
        this.err = `L'équipe ${team.name} est vide !`;
        return;
      }

      GameService.createRelations(this.room.game.id, this.teams);

      this.$socket.client.emit(
        "validateTeams",
        { pin: this.$route.params.pin },
        (res) => {
          if (res.error) {
            this.err = res.error;
          }
        }
      );
    },
  },
  sockets: {
    // User join a team
    async userJoinsTeam() {
      this.teams = await GameService.getTeamsFromGameId(this.room.game.id);
    },

    // Game is launching
    validateTeams() {
      this.$store.dispatch(UPDATE_ROOM_STATE, GAME_IN_PROGRESS);
      this.$router.push({name: ROUTE_GAME.name});
    },
  },
};
</script>

<style scoped>
 #roomTeam {
   height: 85vh
 }
</style>
